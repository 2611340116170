import Control from 'ol/control/Control';
import { Control, defaults as defaultControls } from 'ol/control';
import Feature from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import { Group , Tile as TileLayer } from 'ol/layer';
//import ImageWMS from 'ol/source/ImageWMS';
import { Image as ImageLayer, Tile as TileLayer } from 'ol/layer';
import Icon from 'ol/style/Icon';
import { Icon, Style } from 'ol/style';
import Map from 'ol/Map';
import 'ol/ol.css';
//import OSM from 'ol/source/OSM';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import Point from 'ol/geom/Point';
//import Polyline from 'ol/format/Polyline';
import { Circle, Fill, Style } from 'ol/style';
import { ScaleLine, defaults as defaultControls } from 'ol/control';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
//import TileJSON from 'ol/source/TileJSON';
import VectorLayer from 'ol/layer/Vector';
import LayerGroup from 'ol/layer/Group';
import View from 'ol/View';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
//import Zoom from 'ol/control/Zoom';
//import Extent from 'ol/extent';
//import { makeInverse } from 'ol/transform';
//const place = [-4.503088, 54.225411];
//const point = new Point(fromLonLat(place));


//===================================================================================================================================================================
// takes a location such as afile.txt  and changes to https://maps.im/afile.txt   (any http://localhost:1234 becomes http://localhost)
//===================================================================================================================================================================
function URLToFinal(urlloc) {
    ToRet = window.location.origin + '/' + urlloc;
    ToRet = ToRet.replace('localhost:1234', 'localhost');     // debugging move from localhost:1234 to just localhost for serving our files
    return ToRet;
}
//===================================================================================================================================================================
// http to https
if (window.location.origin.replace('localhost', '').length == window.location.origin.length)   // not running localhost
{
    if (location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
    }
}
//===================================================================================================================================================================
// Detect if is mobile
var ScaleMarkers = 0.6;
var PopoverWidth = 372;
var PopoverHeight = 208;
if (/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) { // mobile
    ScaleMarkers = 1;
    $("head").append('<style>.popover { width: 600px; }</style>');
    PopoverWidth = 572;
    PopoverHeight = 319;
} else {
    $("head").append('<style>.popover { width: 400px; }</style>');
}
//===================================================================================================================================================================
/*function rotate_image() {
    var newMapLayer;

    if ($(this).is(':checked'))
        newMapLayer = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
    else
        newMapLayer = 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}';

    if (newMapLayer != mapLayer) {
        map.addLayer(newMapLayer);
        map.removeLayer(mapLayer);
        mapLayer = newMapLayer;
    }
}
*/
/*$('#testButton').click(function () {
    console.log('click');
    map.getView().setZoom(map.getView().getZoom() + 1);
});
var graphic = new Image(
    'Document Page',
    "http://www.hdwallpapersinn.com/wp-content/uploads/2013/03/landscape_7.jpg",
    new Bounds(-250, -100, 250, 100),
    new Size(250, 100)
);*/
//map.addLayers([graphic]);
/*var layersOSM = new Group({
    layers: [
        new TileLayer({
            source: new OSM()
        })
    ]
});
var layersMQ = new Group({
    layers: [
        new TileLayer({
            source: new MapQuest({ layer: 'osm' })
        })
    ]
});
function setMapType(newType) {
    if (newType == 'OSM') {
        map.setLayerGroup(layersOSM);
    } else if (newType == 'MAPQUEST_OSM') {
        map.setLayerGroup(layersMQ);
    }
}
*/
//===================================================================================================================================================================
//sets position to URL coordinates
const IOM = fromLonLat([-4.543088, 54.225411]);
let zoom = 11;
//const BottomLeft = fromLonLat([-5.112288, 54.021665]);
//const TopRight = fromLonLat([-4.106921, 54.482645]);
//let boundingExtent = Extent.boundingExtent([BottomLeft, TopRight]);
var CurrentWindowLocation = '';
if (window.location.hash !== '') {
    // try to restore center, zoom-level and rotation from the URL
    const hash = window.location.hash.replace('#map=', '');
    const parts = hash.split('/');
    if (parts.length === 3) {
        zoom = parseFloat(parts[0]);
        center = [parseFloat(parts[1]), parseFloat(parts[2])];
        //alert(center);
    }
}
window.addEventListener('popstate', function (event) {
    // Log the state data to the console
    console.log(event.state);
    if (window.location.hash !== '' && window.location.hash !==CurrentWindowLocation) {
        // try to restore center, zoom-level and rotation from the URL
        const hash = window.location.hash.replace('#map=', '');
        const parts = hash.split('/');
        if (parts.length === 3) {
            zoom = parseFloat(parts[0]);
            center = [parseFloat(parts[1]), parseFloat(parts[2])];
            view = map.getView();
            view.setCenter(center);
            view.setZoom(zoom);
            map.render();
        }
    }
});


//=====================================================================================================================================================================================
//Overlay Layer selector POPUP
var CustomButton;
class RotateNorthControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
        const options = opt_options || {};

        CustomButton = document.createElement('button');
        CustomButton.innerHTML = 'N';

        const element = document.createElement('div');
        element.className = 'rotate-north ol-unselectable ol-control';

//       element.innerHTML += "<p class=\"pop-target\"><a href= \"#\" title=\"\" data-toggle=\"popover\" data-content=\"You world\" data-original-title=\"Wisdom Quotes\"></a></p>";
        
        element.appendChild(CustomButton);

        super({
            element: element,
            target: options.target,
        });

        CustomButton.addEventListener('click', this.handleRotateNorth.bind(this), false);
    }
    handleRotateNorth(event) {

//popupOverlaySelector.setElement = CustomButton;

      //  $(".pop-target a").popover('show');
//            const coordinate = feature.getGeometry().getCoordinates();
//            popupOverlaySelector.setPosition(coordinate);
            $(elementOverlaySelector).popover({
                container: elementOverlaySelector.parentElement,
                html: true,
                sanitize: false,
                content: 'HI',
                placement: 'top',
            });
            $(elementOverlaySelector).popover('show');
    }
}



//===================================================================================================================================================================
//MAP
var bingLayer = new TileLayer({
    name: 'Test_bing',
    source: new XYZ({
        url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
    })
})
const map = new Map({
    controls: defaultControls().extend([
        new ScaleLine({
            units: 'metric',
        
        }),
        new RotateNorthControl()
    ]),
    target: 'map',
    layers: [
        new TileLayer({
            source: new XYZ({
                url: 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',

                      attributions:
                    '� <a href=" https://www.google.com/maps/' +
                    '">Google</a>',                 
            })
        }),
    ],
    view: new View({
        center: IOM,
        zoom: zoom,
        enableRotation: false,
        extent:[- 572513.341856, 7161017.966314, -416327.095083, 7276950.728974]
 //       extent: boundingExtent
    })
});


//===================================================================================================================================================================
// Load our layers
function AddOurLayers(urlloc) {
    // read text from URL location
    var request = new XMLHttpRequest();
    request.open('GET', URLToFinal(urlloc), true);
    request.send(null);
    request.onreadystatechange = function () {
        if (request.readyState === 4 && request.status === 200) {
            var type = request.getResponseHeader('Content-Type');
            if (type.indexOf("text") !== 1) {
                var Response = request.responseText;
                var MapsToAdd = Response.split("\r\n");
                for (var i = 1; i < MapsToAdd.length; i++) {
                    if (i == 1) {
                        var ALayer = new TileLayer({
                            source: new TileWMS({
                                url: MapsToAdd[0],
                                params: { 'LAYERS': MapsToAdd[i], 'TILED': true },
                                serverType: 'geoserver',
                                transition: 0,
                                attributions:
                                    '� <a href="https://www.dronesurvey.im/' +
                                    '">DroneSurvey.im</a>',
                            }),
                            zIndex: 1
                        });
                        map.addLayer(ALayer);
                    } else {
                        var ALayer = new TileLayer({
                            source: new TileWMS({
                                url: MapsToAdd[0],
                                params: { 'LAYERS': MapsToAdd[i], 'TILED': true },
                                serverType: 'geoserver',
                                transition: 0,
                            }),
                        });
                        map.addLayer(ALayer);
                    }
                }
            }
        }
    }
}
AddOurLayers('!iommaps.txt');



$('input[class=set]').click(function () {
    alert("Change some parameters");
    var group = map.getLayerGroup();
    var layers = group.getLayers();
    var element = layers.item(0);
    var name = element.get('name');
    alert(name);
    if (name != "Test_bing") {
        map.removeLayer(TileLayer);
        map.addLayer(bingLayer);
    } else {
        map.removeLayer(bingLayer);
        map.addLayer(TileLayer);
    }
});




//var popup2 = new Overlay.Popup();
//map.addOverlay(popup2);


//===================================================================================================================================================================
//MARKERS
class MarkerOnMap {
    constructor(ShowName, InfoLine, InternalFolder, ShowAtZoomLevel, Latitude, Longitude) {
        this.ShowName = ShowName;
        this.InfoLine = InfoLine;
        this.InternalFolder = InternalFolder;       // has to be unique
        this.ShowAtZoomLevel = ShowAtZoomLevel;
        this.Latitude = Latitude;           // NS
        this.Longitude = Longitude;         // EW
        this.IsShowing = false;
    }
}
let MarkersOnMap = [];
var MarkersGrp = new LayerGroup({
    title: 'Markers',
    layers: []
});
map.addLayer(MarkersGrp); //this add a new layergroup
function ProcessMarkersToMap() {
    var Zoom = map.getView().getZoom();
    var ReGenAllLayer = false;
    for (iMarker = 0; iMarker < MarkersOnMap.length; iMarker++) {
        if (MarkersOnMap[iMarker].ShowAtZoomLevel > Zoom) {
            if (MarkersOnMap[iMarker].IsShowing == true)
                ReGenAllLayer = true;
        } else {
            if (MarkersOnMap[iMarker].IsShowing == false)
                ReGenAllLayer = true;
        }
    }
    if (ReGenAllLayer == true) {
        MarkersGrp.getLayers().clear();
        for (iMarker = 0; iMarker < MarkersOnMap.length; iMarker++) {
            MarkersOnMap[iMarker].IsShowing = false;
            if (MarkersOnMap[iMarker].ShowAtZoomLevel > Zoom)
                continue;
            MarkersOnMap[iMarker].IsShowing = true;
            var marker = new Feature({
                title: 'IOMMarker-' + iMarker,
                geometry: new Point(
                    fromLonLat([MarkersOnMap[iMarker].Longitude, MarkersOnMap[iMarker].Latitude])
                ),
            });
            var markerVectorLayer = new VectorLayer({
                title: iMarker,
                source: new VectorSource({
                    features: [marker]
                }),
                style: new Style({
                    image: new Icon({
                        src: URLToFinal('3dmodels/' + MarkersOnMap[iMarker].InternalFolder + '/3dmarker.png'),
                        size: [92, 92],
                        scale: ScaleMarkers,
                        anchor: [0.5, 1.0]
                    })
                }),
                zIndex: 2
            });
            MarkersGrp.getLayers().push(markerVectorLayer);
        }
    }
}
function AddOurMarkers(urlloc) {
    // read text from URL location
    var request = new XMLHttpRequest();
    request.open('GET', URLToFinal(urlloc), true);
    request.send(null);
    request.onreadystatechange = function () {
        if (request.readyState === 4 && request.status === 200) {
            var type = request.getResponseHeader('Content-Type');
            if (type.indexOf("text") !== 1) {
                var Response = request.responseText;
                var MarkersToAdd = Response.split("\r\n");
                const ElementsInMarkerstxt = 6;
                for (var i = 0; i < MarkersToAdd.length / ElementsInMarkerstxt; i++) {        // 5 elements in each marker
                    var ShowName = MarkersToAdd[0 + (i * ElementsInMarkerstxt)];
                    var InfoLine = MarkersToAdd[1 + (i * ElementsInMarkerstxt)];
                    var InternalFolder = MarkersToAdd[2 + (i * ElementsInMarkerstxt)];
                    var ShowAtZoomLevel = MarkersToAdd[3 + (i * ElementsInMarkerstxt)];
                    var Latitude = MarkersToAdd[4 + (i * ElementsInMarkerstxt)];
                    var Longitude = MarkersToAdd[5 + (i * ElementsInMarkerstxt)];
                    if (ShowName != '') {
                        MarkersOnMap.push(new MarkerOnMap(ShowName, InfoLine, InternalFolder, ShowAtZoomLevel, Latitude, Longitude));
                    }
                }
                ProcessMarkersToMap();
            }
        }
    }
}
AddOurMarkers('!iommapmarkers.txt');
//var markersVisable = false;
var currZoom = 0;
//const ShowMarkersZoom = 12;
//===================================================================================================================================================================
//
map.on('moveend', function (e) {
    var view = map.getView();
    var newZoom = view.getZoom();
    if (currZoom != newZoom)
    {
        ProcessMarkersToMap();
        currZoom = newZoom;
    }
    //this is bound to the map, so:
    const center = view.getCenter();
    CurrentWindowLocation =
        '#map=' +
        view.getZoom().toFixed(2) +
        '/' +
        center[0].toFixed(2) +
        '/' +
        center[1].toFixed(2);
    window.location.hash = CurrentWindowLocation;
});
//===================================================================================================================================================================
//Marker POPUP
const elementMarker = document.getElementById('popup');
const popupMarker = new Overlay({
    element: elementMarker,
    positioning: 'bottom-center',
    stopEvent: true,
    offset: [0, -10],
});
map.addOverlay(popupMarker);

map.on('click', function (event) {
    $(elementMarker).popover('dispose');
 //   console.info(map.getView().getCoordinates());
    const feature = map.getFeaturesAtPixel(event.pixel)[0];
    if (feature) {
        var Title = feature.get('title');
        var iMarker = Title.replace('IOMMarker-', '');
        var view = map.getView();
//console.info(Title);
        if (iMarker.length < Title.length) {
            const coordinate = feature.getGeometry().getCoordinates();
            var LinkURL = URLToFinal('3dmodels/' + MarkersOnMap[iMarker].InternalFolder + '/');
            popupMarker.setPosition(coordinate);
            $(elementMarker).popover({
                container: elementMarker.parentElement,
                html: true,
                sanitize: false,
                content: '<table><tr><td style="vertical-align:top"><a class="link" href="' + LinkURL + '"><img width="' + PopoverWidth + 'px" height="'+ PopoverHeight +'px" src="' + URLToFinal('3dmodels/' + MarkersOnMap[iMarker].InternalFolder + '/popupimg.jpg') + '"></a></td>' +
                    '<tr><td style="vertical-align:top"><a class="programtitle link" href="' + LinkURL + '">' + MarkersOnMap[iMarker].ShowName + '</a></td></tr>' +
                    '<tr><td class="main">' + MarkersOnMap[iMarker].InfoLine + "</td></tr>" +
                    '<tr><td style="vertical-align:top"><div style="text-align:right;"><a class="link" href="' + LinkURL + '"><img height="75px" src="' + URLToFinal('images/info.png') + '"></a><a class="link" href="' + LinkURL + '"><img height="75px" src="' + URLToFinal('images/viewin3d.png') + '"></a></div></td></tr>' +
                    '</table>',
                placement: 'top',
            });
            $(elementMarker).popover('show');
        }
    } else {
        $(elementMarker).popover('dispose');
    }
});
//CURSOR CHANGE
map.on('pointermove', function (event) {
    if (map.hasFeatureAtPixel(event.pixel)) {
        map.getViewport().style.cursor = 'pointer';
    } else {
        map.getViewport().style.cursor = 'inherit';
    }
});
map.on('movestart', function () {
    $(elementMarker).popover('dispose');
});






//https://viglino.github.io/ol-ext/examples/control/map.switcher.image.html